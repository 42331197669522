<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.ENTERPRICE.STORE.NOMENCLATURE') }}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <div>
            <b-tabs content-class="mt-3" fill v-model="tabIndex">
              <div class="card-button text-right">
                <create :index="tabIndex" />
                <!-- <div>
                  <b-button variant="success" id="show-btn" @click="showModal"
                    ></b-button
                  >

                  <b-modal
                    ref="my-modal"
                    hide-footer
                    title="Nomenklatura registratsiyasi"
                  >
                    <div class="d-block">
                      <div>
                        <v-autocomplete
                          v-model="measurement_Input"
                          :items="getDataMe"
                          label="Oʻlchamlar"
                          item-text="name"
                          outlined
                          dense
                          return-object
                        ></v-autocomplete>
                      </div>

                      <div class="form-group">
                        <label>Nomenklatura nomi</label>
                        <input
                          type="text"
                          placeholder="Nomenklatura nomi"
                          class="form-control form-control-solid form-control-lg"
                          v-model.trim="$v.firstname.$model"
                          :class="{
                            'is-invalid': $v.firstname.$error,
                            'is-valid': !$v.firstname.$invalid,
                          }"
                        />
                        <div class="valid-feedback"></div>
                        <div class="invalid-feedback">
                          <span v-if="!$v.firstname.required"
                            >Nomenklatura toldirilishi shart</span
                          >
                          <span v-if="!$v.firstname.alpha"
                            >Nomenklatura son bolishi mumkin emas</span
                          >
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Kod</label>
                        <input
                          type="text"
                          placeholder="Nomenklatura kodi"
                          class="form-control form-control-solid form-control-lg"
                          v-model.trim="$v.kodInput.$model"
                          :class="{
                            'is-invalid': $v.kodInput.$error,
                            'is-valid': !$v.kodInput.$invalid,
                          }"
                        />
                        <div class="valid-feedback"></div>
                        <div class="invalid-feedback">
                          <span v-if="!$v.kodInput.required"
                            >Kod toldirilishi shart</span
                          >
                          <span v-if="!$v.kodInput.maxLength"
                            >Kod {{ $v.kodInput.$params.maxLength.max }} ta
                            bolish kerak test
                          </span>
                          <span v-if="!$v.kodInput.minLength"
                            >Kod {{ $v.kodInput.$params.minLength.min }} ta
                            bolish kerak
                          </span>
                          <span v-if="!$v.kodInput.numeric"
                            >Kod son bolish kerak
                          </span>
                        </div>
                      </div>
                    </div>
                    <b-button
                      class="mt-3"
                      variant="outline-warning"
                      block
                      @click="hideModal"
                      >Bekor qilish</b-button
                    >
                    <b-button
                      class="mt-2"
                      variant="outline-success"
                      block
                      @click="submit(tabIndex + 1), toggleModal"
                      >Yuborish</b-button
                    >
                  </b-modal>
                </div> -->
              </div>
              <b-tab
                v-model="tabName"
                v-for="i in allNomenklaturaGroup"
                :key="'dyn-tab-' + i.id"
                :title="i.name"
                @click="tabAction(i.id)"
              >
                <div>
                  <v-data-table
                    :headers="headers"
                    :items="getAllNomen"
                    :items-per-page="10"
                    :page.sync="page"
                    no-data-text="Malumot mavjud emas"
                    hide-default-footer
                    single-select
                    @click:row="rowClick"
                    item-key="id"
                    class="elevation-1 row-pointer"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <div style="text-align: right !important">
                        <nomenklaturaAction
                          :tabid="tabIndex + 1"
                          :index="item"
                        ></nomenklaturaAction>
                      </div>
                    </template>
                  </v-data-table>
                  <div class="text-center">
                    <v-pagination
                      v-model="page"
                      :length="Math.ceil(getAllNomen.length / 10)"
                    ></v-pagination>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import { mapGetters, mapActions } from "vuex";
import nomenklaturaAction from '@/view/pages/sklad/nomenklatura/nomenklaturaAction'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  minLength,
  alpha,
  numeric
} from 'vuelidate/lib/validators'
import create from './create'

export default {
  components: {
    nomenklaturaAction,
    create
  },
  mixins: [validationMixin],
  validations: {
    kodInput: {
      required,
      maxLength: maxLength(4),
      minLength: minLength(4),
      numeric
    },
    firstname: { required, alpha }
  },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      selectedName: null,
      searchableName: false,
      searchable: true,
      textProp: true,
      selectedContinent: null,
      showDefaultOption: false,
      searchableContinent: false,
      selectedValue: null,
      perPage: 10,
      tabIndex: '',
      submitStatus: null,
      page: 1,
      tabName: '',
      firstname: '',
      measurementInput: '',
      kodInput: ''
    }
  },
  created() {
    this.$store.dispatch('getNomenklaturaByGroup', this.tabIndex + 1)
    this.$store.dispatch('getNomenklaturaGroup')
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NOMENCLATURE_NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.MEASUREMENTS_NAME'),
          value: 'measurement_name'
        },
        {
          text: this.$t('TABLE_HEADER.CODE'),
          value: 'code'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    title() {
      return this.selectedValue
        ? this.getOptionLabel(this.selectedValue)
        : this.labelTitle
    },
    getAllNomen() {
      return this.$store.state.requests.allNomenklature
    },
    allNomenklaturaGroup() {
      return this.$store.state.requests.nomenklaturaGroup
    },
    getDataMe() {
      const data = this.$store.state.requests.measurements

      return data
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    tabAction(value) {
      this.$store.dispatch('getNomenklaturaByGroup', value)
    }
    // setName(value) {
    //   this.name = value
    //   this.$v.name.$touch()
    // }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.STORE.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.STORE.NOMENCLATURE') }
    ])
  },
  props: {
    labelTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.card-button {
  margin: 30px 0;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
<style scoped>
.navi /deep/ .navi-item /deep/ .navi-link {
  padding: 0.75rem 1rem !important;
}
.v-select /deep/ .v-select-toggle {
  padding: 0.8rem 1rem !important;
  background-color: #f3f6f9 !important;
}
.v-select /deep/ .v-dropdown-container ul {
  height: 400px;
  overflow-y: scroll;
}
</style>
