<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="success" v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.STORE.NOMENCLATURE') }} +
        </v-btn>
      </template>
      <v-card>
        <v-card-title> Nomenklatura registratsiyasi</v-card-title>
        <v-card-text>
          <div>
            <v-autocomplete
              v-model="measurement_Input"
              :items="getDataMe"
              label="Oʻlchamlar"
              item-text="name"
              item-value="id"
              outlined
              dense
              return-object
            ></v-autocomplete>
          </div>
          <div>
            <div>
              <v-text-field
                label="Nomi"
                dense
                outlined
                v-model="nameinput"
              ></v-text-field>
            </div>
          </div>

          <div>
            <v-text-field
              label="Kod"
              v-model="key"
              outlined
              :rules="rules"
              dense
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="newNomenklaturaLoading"
            @click="dialog = false"
          >
            Bekor qilish
          </v-btn>
          <v-btn
            color="success"
            :disabled="newNomenklaturaLoading"
            @click="submit(index + 1)"
          >
            <i v-if="newNomenklaturaLoading" class="el-icon-loading"></i>
            Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newNomenklaturaLoading: false,
      rules: [(value) => (value || '').length <= 4 || 'Max 4 characters'],
      dialog: false,
      nameinput: '',
      key: '',
      measurement_Input: ''
    }
  },
  async mounted() {
    await this.$store.dispatch('getMeasurements')
  },
  computed: {
    getDataMe() {
      return this.$store.state.requests.measurements
    }
  },
  methods: {
    submit(id) {
      const data = {
        name: this.nameinput,
        code: this.key,
        measurement: this.measurement_Input.id
      }
      data.group = id
      this.newNomenklaturaLoading = true
      this.$store
        .dispatch('createNomenklatura', { id, data })
        .then(() => {
          this.newNomenklaturaLoading = false
          this.dialog = false
          this.nameinput = ''
          this.key = ''
          this.measurement_Input = ''
        })
        .catch((err) => {
          this.newNomenklaturaLoading = false
          console.error(err)
        })
    }
  },
  props: {
    index: Number
  }
}
</script>

<style scoped></style>
