<template>
  <div>
    <div class="navi-link" id="show-btn" @click="showModal(item.id)">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
    </div>

    <b-modal ref="my-modal" hide-footer title="Nomenklatura o’zgartirish">
      <div class="d-block">
        <div class="form-group">
          <label>Guruhlar</label>
          <v-combobox
            v-model="group"
            :items="getAllNomenklaturaGroup"
            item-text="name"
            outlined
            return-object
            dense
          ></v-combobox>
        </div>
        <div class="form-group">
          <label>Oʻlchamlar</label>

          <!-- <v-select
            v-model="selectedContinent.name"
            :options="getDataMe"
            :placeholder="sss"
            :labelTitle="labelTitle"
            :searchable="searchable"
            :showDefaultOption="showDefaultOption"
          /> -->
          <v-combobox
            v-model="selectedContinentInput"
            :items="getDataMe"
            item-text="name"
            outlined
            return-object
            dense
          ></v-combobox>
        </div>
        <div class="form-group" v-if="test !== undefined">
          <label>Nomenklatura nomi</label>

          <input
            type="text"
            v-model="test.name"
            class="form-control form-control-solid form-control-lg"
          />
        </div>

        <div class="form-group" v-if="test !== undefined">
          <label>Kod</label>
          <input
            type="text"
            v-model="test.code"
            placeholder="Nomenklatura kodi"
            class="form-control form-control-solid form-control-lg"
          />
          <div class="valid-feedback"></div>
          <div class="invalid-feedback"></div>
        </div>
      </div>
      <b-button
        class="mt-3"
        variant="outline-warning"
        block
        @click="hideModalUpdate"
        >Bekor qilish</b-button
      >
      <b-button
        class="mt-2"
        variant="outline-success"
        block
        @click="submitUpdate(testidds)"
        >Saqlash</b-button
      >
    </b-modal>
  </div>
</template>

<script>
// import VSelect from "@alfsnd/vue-bootstrap-select";
export default {
  data() {
    return {
      selectedName: null,
      searchableName: false,
      searchable: true,
      textProp: true,
      showDefaultOption: false,
      searchableContinent: false,
      selectedValue: null,
      id: '',
      selectedContinentInput: '',
      group: ''
    }
  },
  beforeCreate() {
    this.$store.dispatch('getMeasurements')
    this.$store.dispatch('getNomenklatura')
    this.$store.dispatch('getNomenklaturaGroup')
  },
  created() {
    this.selectedContinentInput = this.selectedContinent.name
  },
  computed: {
    selectedContinent() {
      return this.$store.state.requests.measurements.find(
        (obj) => obj.id == this.item.measurement
      )
    },
    getDataMe() {
      const data = this.$store.state.requests.measurements
      return data
    },
    getAllNomenklaturaGroup() {
      const data = this.$store.state.requests.nomenklaturaGroup
      return data
    },
    // ...mapGetters({ test: "getAllNomenklatura" }),
    // testobk() {
    //   return this.test.find(x => x.id === this.id);
    // }
    test() {
      const data = this.$store.state.requests.allNomenklatureAll
      return data.find((x) => x.id == this.id)
    }
    // doneTodosCount() {
    // this.$store.dispatch("getAllNomenklatura");

    //     return this.$store.getters.getAllNomenklatura;
    //   const data = this.$store.getters.getAllNomenklatura;
    //   return data.find(x => x.id === this.id);
    // }
  },
  methods: {
    submitUpdate(value) {
      const tabid = {
        id: value
      }
      const data = {
        id: this.test.id,
        name: this.test.name,
        code: this.test.code,
        group: this.test.group
      }
      if (this.selectedContinentInput.hasOwnProperty('name') == false) {
        data.measurement = this.test.measurement
      } else if (this.selectedContinentInput.hasOwnProperty('name') == true) {
        data.measurement = this.selectedContinentInput.id
      }
      if (this.group.hasOwnProperty('name') == false) {
        data.group = this.test.group
      } else if (this.group.hasOwnProperty('name') == true) {
        data.group = this.group.id
      }
      this.$store.dispatch('updateNomenklatura', { tabid, data })
      this.$refs['my-modal'].hide()
    },
    showModal(value) {
      this.id = value
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    hideModalUpdate() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    }
  },
  components: {
    // VSelect
  },
  props: {
    item: Object,
    index: Object,
    tabid: Number,
    testidds: Number
  }
}
</script>

<style scoped>
.navi /deep/ .navi-item /deep/ .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
